:root {
  background-color: #ffffff;
}

:body {
  font-family: "Roboto";
}

.TopNav {
  background-color: #c8eaf9 !important;
}

.logo_img {
  width: 8vh;
}

.hli_bt {
  background-color: #ffffff;
}