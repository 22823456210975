/* login page */

.bg1-login{
    background: #f6d7e5;
    padding-bottom: 5%;
    min-height: 100vh;
}
.bg2-login{
    background-color: #f6edf1;
    width: 85%;
    margin-left: 7.5%;
    border-radius: 2vh;
}
.logo-img{
    width: 75%;
    margin-top: 25%;
    margin-left: 12.5%;
}
.word-login-padding{
    text-align : left;
    padding-left: 10%;
}

.text-box-login{
    border-radius:4vw;
    padding: 1% 0%;
    padding-left: 5%;
    width: 75%;
    background-color: white;
    text-align: left;
    color: black;
    margin-left: 5%;
    font-size: 2.5vw;
    margin-top: -3%;

}

.text-above-login{
    font-size: 4vw;
    padding-left: 9%;
    padding-bottom: 0%;
}

.login-button{
    background-color: #ffee98;
    border: none;
    border-radius: 5vw;
    padding: 3% 5%;
    font-size: 5vw;
    margin-top: 10%;
    margin-left: 0%;
    width: 60%;
}

.forget-margin{
    margin-top: 15%;
    padding-bottom: 15%;
    font-size: 3vw;
    margin-left: 35%;

}
.forget-link{
    border: none;
}

/* regis page */

.back-icon{
    font-size: 5vw;
    text-align: center;
    background-color: white;
    width: 6%;
    border-radius: 50%;
    margin-left: 5%;
    padding-left: 0.5%;
}

.add-photo{
    margin-top: 2%;
    margin-bottom: 2%;
}

.upload-button{
    color: #f6d7e5;
}

#input[type="file"]{
    display: none;
}

.label{
    display: flex;
    justify-content: center;
    background-color: #FFEE99;
    width: 20%;
    padding: 2% 0%;
    font-size: 3.5vw;
    border-radius: 3vw;
    margin-left: 40%;
}

.calendar-data{
    border: none;
    /* width: 145%; */
    font-size: 3.5vw;
    padding: 5% 0%;
    /* border-radius:4vw; */
    /* padding: 4% 5%; */
}

.date-bg{
    border-radius:4vw;
    height: 5%;
    padding: 0% 5%;
    width: 70%;
    background-color: white;
    text-align: left;
    color: black;
    margin-left: 5%;
    font-size: 2.5vw;
    margin-top: -3%;
}

.gender-radio{
    font-size: 4vw;

}

.container {
    display: block;
    position: relative;
    padding-left: 20%;
    cursor: pointer;
    font-size: 3.5vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 20%;
    margin-top: -5%;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 5vw;
    width: 5vw;
    background-color: white;
    border-radius: 50%;
    margin-top: 0%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: white;
}
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
}
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
       top: 2vw;
      left: 2vw;
      width: 1vw;
      height: 1vw;
      border-radius: 50%;
	background: white;
    }

  .grid-gender{
      width: 20%;
  }

  .text-danger{
      margin-top: 2%;
      margin-left: 10%;
      color: red;
  }


  /* add baby */

  .text-add{
      background-color: white;
      width: 38%;
      padding: 2% 3%;
      margin-left: 28%;
      font-size: 4.5vw;
      border-radius: 1vw;
      margin-top: 0%;

  }

  .text-baby-bg{
    background-color: black;
    width: 100%;

  }
  
  .green-bg{
      background-color: #A5DFD3;
      padding-bottom: 20%;
      margin-top: -2.5%;

  }

  .span-text{
    margin-left: 5%;
    font-size: 4vw;
    padding-left: 9%;
    padding-bottom: 0%;
  }

  .span-input{
    border-radius:2vw;
    padding: 2% 0%;
    padding-left: 5%;
    width: 50%;
    background-color: white;
    text-align: left;
    color: black;
    margin-left: 5%;
    font-size: 2.5vw;
    margin-top: -3%;

}

.date-baby{
    margin-left: 0%;
    padding: 0%;
}
.calendar-data2{
    border: none;
    font-size: 3.5vw;
    padding: 3% 5%;
    border-radius:2vw;
    /* padding: 4% 5%; */
}

.submit-button{
    background-color: #F89A8C;
    border: none;
    border-radius: 3vw;
    padding: 1% 3%;
    font-size: 4vw;
    margin-top: 10%;
    width: 20%;
}

.cancel-button{
    background-color: #1487B8;
    border: none;
    border-radius: 3vw;
    padding: 1.5% 4%;
    font-size: 4vw;
    margin-top: 10%;
    margin-left: 0%;
    width: 35%;
    text-decoration: none;
    color: #000;
}
